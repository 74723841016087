import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import DefaultLayout from "../layouts/default";
import TitleXL from "../components/TitleXL";
import InhaltsText from "../components/InhaltsText";
import Wrapper from "../components/Wrapper";
import HorizontalWrapper from "../components/HorizontalWrapper";
import { Helmet } from "react-helmet";

interface DatenschutzQueryProps {
  cms: {
    entry: {
      title: string;
      inhaltstext: string;
    };
  };
}

const Datenschutz = () => {
  const data: DatenschutzQueryProps = useStaticQuery(graphql`
    query {
      cms {
        entry(type: "datenschutzerklarung") {
          title
          ... on CMS_andereInhalte_datenschutzerklarung_Entry {
            inhaltstext
          }
        }
      }
    }
  `);
  const entry = data.cms.entry;

  return (
    <DefaultLayout>
      <Helmet>
        <title>{entry.title} – Niederegger AG</title>
      </Helmet>
      <HorizontalWrapper>
        <TitleXL spacingTop>{entry.title}</TitleXL>
      </HorizontalWrapper>
      <Wrapper>
        <InhaltsText html={entry.inhaltstext} />
      </Wrapper>
    </DefaultLayout>
  );
};

export default Datenschutz;
